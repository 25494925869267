import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const CardWork = ({ data }) => {
  const {
    background,
    contentful_id,
    country,
    cover,
    id,
    slug,
    service,
    title,
  } = data;
  return (
    <div key={id} className='col-12 col-md-6 mb-3'>
      <div className='work-card' style={{ background: background }}>
        <Link to={`/projects/${slug}`}>
          <div className='work-cover'>
            {cover && (
              <GatsbyImage image={cover.gatsbyImageData} alt={cover.title} />
            )}
          </div>
          <div className='work-content'>
            <div className='work-title'>{title}</div>
            <div className='work-stack'>
              {service.map(({ title }) => title).join(', ')}
            </div>
            <div className='work-country'>
              <img
                src={'/images/icons/location.svg'}
                height={16}
                width={16}
                alt='Location icon'
                loading='lazy'
              />
              {country}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CardWork;
