import React from 'react';

const CardFeature = ({ data }) => {
  const { id, description, image, title } = data;

  return (
    <div key={id} className='col-6 col-md-6 col-lg-4 mb-2'>
      <div className='feature'>
        {image && (
          <div className='feature-image'>
            <img src={image} alt={title} width={72} height={72} />
          </div>
        )}
        <h2 className='feature-title'>{title}</h2>
        <div className='feature-content'>{description}</div>
      </div>
    </div>
  );
};

export default CardFeature;
