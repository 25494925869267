import React from 'react';

const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = React.useState(true);

  React.useEffect(() => {
    if (window) {
      const userAgent =
        typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

      const mobile = Boolean(
        userAgent.match(
          /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
        )
      );

      const isMobileScreen = window.innerWidth < 768;

      setIsMobile(mobile || isMobileScreen);
    }
  }, []);

  return { isMobile };
};

export default useDeviceDetect;
