import React, { useCallback } from 'react';
import { Link, graphql, withPrefix } from 'gatsby';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';

import SEO from '../components/SEO';
import CardFeature from '../components/CardFeature';
import CardWork from '../components/CardWork';
import CardBlog from '../components/CardBlog';
import Layout from '../components/Layout';
import useDeviceDetect from '../../utils/useDeviceDetect';
import scrollToBookmark from '../../utils/scrollToBookmark';

const TestimonialLazy = React.lazy(() => import('../components/Testimonial'));

const Home = (props) => {
  const features = props.data.features.edges;
  const works = props.data.works.edges;
  const testimonials = props.data.testimonial.edges;
  const blogs = props.data.blogs.edges;
  const { isMobile } = useDeviceDetect();
  const isSSR = typeof window === 'undefined';

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(() => {
    //
  }, []);

  return (
    <Layout bodyClass='page-home'>
      <div className='intro'>
        <div className='container'>
          <div className='row justify-content-center pt-4 pt-md-0'>
            <div className='col-12 col-sm-10 col-md-8 col-lg-6'>
              <div>
                <h1>
                  Hi, My name is
                  <br />
                  &lt;Yasin Junet/&gt;
                </h1>
                <p>
                  I'm an Indonesian{' '}
                  <strong>front-end engineer / freelancer</strong> that will
                  help take your online presence to the next level
                </p>
                <a
                  href='#projects'
                  className='button button-secondary'
                  onClick={(e) => scrollToBookmark(e, { element: 'projects' })}
                >
                  See Featured Projects
                </a>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className='particles'>
            <Particles
              init={particlesInit}
              loaded={particlesLoaded}
              options={{
                fpsLimit: 60,
                interactivity: {
                  events: {
                    onClick: {
                      enable: true,
                      mode: 'push',
                    },
                    onHover: {
                      enable: true,
                      mode: 'repulse',
                    },
                    resize: true,
                  },
                  modes: {
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 100,
                      duration: 0.4,
                    },
                  },
                },
                particles: {
                  color: {
                    value: '#ffffff',
                  },
                  links: {
                    color: '#ffffff',
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    directions: 'none',
                    enable: true,
                    outModes: {
                      default: 'bounce',
                    },
                    random: false,
                    speed: 4,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 800,
                    },
                    value: 35,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: 'circle',
                  },
                  size: {
                    value: { min: 1, max: 5 },
                  },
                },
                detectRetina: true,
                fullScreen: {
                  enable: false,
                },
              }}
            />
          </div>
        )}
      </div>

      {features.length > 0 && (
        <div id='services' className='strip strip-dark'>
          <div className='container pt-6 pb-6 pt-md-10 pb-md-10'>
            <span className='section-title section-title-white mb-5'>
              Our Services
            </span>
            <div className='row justify-content-center'>
              {features.map(({ node }) => (
                <CardFeature key={node.id} data={node} />
              ))}
            </div>
          </div>
        </div>
      )}

      {works.length > 0 && (
        <div id='projects' className='strip strip-works'>
          <div className='container pt-6 pb-6 pt-md-8 pb-md-8 pt-lg-10 pb-lg-10'>
            <span className='section-title mb-3'>Projects</span>
            <div className='row'>
              {works.map(({ node }) => (
                <CardWork key={node.id} data={node} />
              ))}
            </div>
            <div className='row pt-1'>
              <div className='col-auto'>
                <Link className='btn-explore' to='/projects/'>
                  See All Projects{' '}
                  <img
                    src='/images/icons/arrow-left.svg'
                    alt='Arrow Left'
                    height={16}
                    width={16}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {blogs.length > 0 && (
        <div className='strip strip-blog strip-blog-home'>
          <div className='container pt-6 pb-6 pt-md-8 pb-md-8 pt-lg-10 pb-lg-10'>
            <span className='section-title mb-3'>Blogs</span>
            <div className='row'>
              {blogs.map(({ node }) => (
                <CardBlog key={node.id} data={node} />
              ))}
            </div>
            <div className='row pt-1'>
              <div className='col-auto'>
                <Link className='btn-explore' to='/blog/'>
                  Explore Blogs{' '}
                  <img
                    src='/images/icons/arrow-left.svg'
                    alt='Arrow Left'
                    height={16}
                    width={16}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <TestimonialLazy testimonials={testimonials} />
        </React.Suspense>
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    features: allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
    works: allContentfulWorks(
      filter: { node_locale: { eq: "en-US" } }
      limit: 6
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          contentful_id
          country
          title
          background
          slug
          stack
          short {
            short
          }
          desc {
            raw
          }
          cover {
            title
            gatsbyImageData(
              width: 600
              height: 450
              quality: 95
              placeholder: BLURRED
              formats: [AUTO, PNG, WEBP]
            )
          }
          service {
            title
          }
        }
      }
    }
    testimonial: allTestimonialsJson {
      edges {
        node {
          id
          company
          content
          name
          rate
        }
      }
    }
    blogs: allContentfulBlog(
      filter: { node_locale: { eq: "en-US" } }
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          category {
            name
          }
          createdAt(formatString: "MMMM DD, YYYY")
          cover {
            title
            gatsbyImageData(
              width: 360
              height: 210
              quality: 85
              placeholder: BLURRED
              formats: [AUTO, PNG, WEBP]
            )
          }
          slug
          title
          post {
            raw
          }
        }
      }
    }
    allSocialJson {
      edges {
        node {
          name
          image
          link
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Head = () => <SEO />;

export default Home;
